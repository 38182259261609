import "./Dashboard.css";

import { Component } from "react/cjs/react.production.min";
import TotalTweets from "./Widgets/TotalTweets/TotalTweets";
import Timeline from "./Widgets/Timeline/Timeline";
import WordCloud from "./Widgets/WordCloud/WordCloud";
import LineChart from "./Widgets/LineChart/LineChart";
import Heatmap from "./Widgets/Heatmap/Heatmap";
import Locations from "./Widgets/Locations/Locations";
import DownloadPostsButton from "components/Popups/DownloadPostsPopup/DownloadPostsButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DashboardContext } from "contexts/DashboardContext";
import TotalPostsPopup from "components/Popups/WidgetsPopup/TotalPostsPopup";
import TimelinePopup from "components/Popups/WidgetsPopup/TimelinePopup";
import WordCloudPopup from "components/Popups/WidgetsPopup/WordCloudPopup";
import LinechartPopup from "components/Popups/WidgetsPopup/LinechartPopup";
import HeatmapPopup from "components/Popups/WidgetsPopup/HeatmapPopup";
import LocationsPopup from "components/Popups/WidgetsPopup/LocationsPopup";

export default class Dashboard extends Component {
    static contextType = DashboardContext;

    render() {
        return (
            <div className="DashboardContainer">
                <div className="Dashboard">
                    <div className="Widget x1" id="TotalTweets">
                        <div className="Title"> 
                            <span>
                                Total Posts
                                <FontAwesomeIcon 
                                    icon={ faInfoCircle } 
                                    className="InfoIcon"
                                    onClick={() => {
                                        this.context.setPopupTitle("Total Posts");
                                        this.context.setPopupContent(<TotalPostsPopup/>);
                                        this.context.setPopupOpen(true);
                                    }}
                                />
                            </span>
                        </div>
                        <div className="Container">
                            <TotalTweets />
                        </div>
                    </div>
                    <div className="Widget x2" id="Heatmap">
                        <div className="Title"> 
                            <span>
                                Heatmap
                                <FontAwesomeIcon 
                                    icon={ faInfoCircle } 
                                    className="InfoIcon"
                                    onClick={() => {
                                        this.context.setPopupTitle("Heatmap");
                                        this.context.setPopupContent(<HeatmapPopup/>);
                                        this.context.setPopupOpen(true);
                                    }}
                                />
                            </span> 
                        </div>
                        <div className="Container">
                            <Heatmap />
                        </div>
                    </div>
                    <div className="Widget x2" id="Locations">
                        <div className="Title"> 
                            <span>
                                Most Mentioned Locations
                                <FontAwesomeIcon 
                                icon={ faInfoCircle } 
                                className="InfoIcon"
                                onClick={() => {
                                    this.context.setPopupTitle("Most Mentioned Locations");
                                    this.context.setPopupContent(<LocationsPopup/>);
                                    this.context.setPopupOpen(true);
                                }}/>
                            </span>
                        </div>
                        <div className="Container">
                            <Locations />
                        </div>
                    </div>
                    <div className="Widget x3" id="Timeline">
                        <div className="Title">
                            <span>
                                Timeline 
                                <FontAwesomeIcon 
                                icon={faInfoCircle} 
                                className="InfoIcon"
                                onClick={() => {
                                    this.context.setPopupTitle("Timeline");
                                    this.context.setPopupContent(<TimelinePopup/>);
                                    this.context.setPopupOpen(true);
                                }}/>
                            </span>
                            <DownloadPostsButton />
                        </div>
                        <div className="Container">
                            <Timeline />
                        </div>
                    </div>
                    <div className="Widget x2" id="LineChart">
                        <div className="Title">
                            <span>
                                Number of Posts per Day
                                <FontAwesomeIcon 
                                icon={faInfoCircle} 
                                className="InfoIcon"
                                onClick={() => {
                                    this.context.setPopupTitle("Number of Posts per Day");
                                    this.context.setPopupContent(<LinechartPopup/>);
                                    this.context.setPopupOpen(true);
                                }}/>
                            </span>
                        </div>
                        <div className="Container">
                            <LineChart />
                        </div>
                    </div>
                    <div className="Widget x2" id="WordCloud">
                        <div className="Title">
                            <span>
                                Keyword Cloud
                                <FontAwesomeIcon 
                                icon={faInfoCircle} 
                                className="InfoIcon"
                                onClick={() => {
                                    this.context.setPopupTitle("Word Cloud");
                                    this.context.setPopupContent(<WordCloudPopup/>);
                                    this.context.setPopupOpen(true);
                                }}/>
                            </span>
                        </div>
                        <div className="Container">
                            <WordCloud />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}