import './UserManual.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import logo from 'resources/images/logo.svg';
import smasOverview from './images/image001.png';
import smasFilters from './images/image003.png';
import smasUseCasesFilter from './images/image005.png';
import smasLanguageFilter from './images/image006.png';
import smasDateFilter from './images/image008.png';
import smasKeywordFilter from './images/image010.png';
import smasLocationFilter from './images/image011.png';
import smasHideRetweetsFilter from './images/image012.png';
import smasOnTheFlyFilters from './images/image013.png';
import widgetsOverview from './images/image014.png';
import totalPostsWidget from './images/image016.png';
import timelineWidget from './images/image018.png';
import mostMentionedLocationsWidget from './images/image019.png';
import heatmapWidget from './images/image020.png';
import clustersHeatmapWidget from './images/image021.png';
import postsPerDayWidget from './images/image022.png';
import wordCloudWidget from './images/image024.png';


export default function UserManual() {
    const navigate = useNavigate();

    const headers = [ 
        { header: "Introduction", subHeaders: [ "What's SMAS" ] },
        { header: "User Interface", subHeaders: [ "Filters component", "Widgets component" ] },
        { header: "API Reference", subHeaders: [ "Query parameters", "Endpoints" ] }
    ];
    const navigation = headers.map((header, index) => {
                    return(
                        <li key={index}>
                            <div onClick={() => {
                                document.getElementById(
                                        header.header.toLowerCase().replace(" ", "-"))
                                    .scrollIntoView({ behavior: "smooth" });
                            }} 
                            style={{ fontWeight: "bold" }}>
                                {header.header}
                            </div>
                            <ol>
                                {header.subHeaders.map((subHeader, index) => {
                                    return(
                                        <li key={index}>
                                            <div onClick={() => {
                                                document.getElementById(
                                                        subHeader.toLowerCase().replace(" ", "-"))
                                                    .scrollIntoView({ behavior: "smooth" });
                                                }}>
                                                {subHeader}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ol>
                        </li>
                    );
                })


    return(
    <div className='Main'>
        <header className='ManualHeader' style={{ display:"flex", padding: "5px 50px" }}>
            <img src={logo} alt="logo" 
            style={{ width: "80px", marginRight: "10px", cursor: "pointer" }}
            onClick={() => navigate('/')}/>
            <h1>
                User manual
            </h1>
        </header>
        <div className='NavCont'>
        <nav className='Navigation'>
            <FontAwesomeIcon 
                icon={faArrowDown} 
                className='DrawerButton'
            />
            <ol style={{  }}>
                {navigation}
            </ol>
        </nav>
        <main className='ManualContent'>
            <section id='introduction'>
                <h2>
                    Introduction
                </h2>
                <section id="what's-smas">
                    <h3>
                        What is SMAS
                    </h3>
                    <p>
                        The Social Media Analytics Suite (SMAS) is a Web 
                        application that enables the end user to retrieve 
                        and visualize the social media data that are collected 
                        and analysed within CALLISTO. SMAS offers 
                        two search modes: either performed on the collections 
                        that are constantly updated by the monitoring of 
                        CALLISTO-PUC-related keywords or on-the-fly search 
                        (new query). In the first case, the user can use the 
                        available filtering options to search the existing collections 
                        and the retrieved social media data (tweets and Instagram posts) 
                        as well as some analytics are displayed on six widgets 
                        (they are later described individually). In the latter case, 
                        the user can query Twitter live for a new keyword and 
                        the most recent tweets (up to 10 days ago) are displayed 
                        along with their analytics on the same six widgets. Please 
                        note that on-the-fly search is not supported for Instagram.
                    </p>
                    <img alt='smas overview' src={smasOverview} style={{ width: '100%', margin: 'auto 0' }}/>
                </section>
                <p>
                    There are two main components that consist the SMAS dashboard:
                </p>
                <ul>
                    <li>
                        The Filters Component that groups all the available filters (depending on the mode selected)
                    </li>
                    <li>
                        The Widgets component that comprises six widgets for visualizing social media data and analytics
                    </li>
                </ul>
            </section>
            <section id="user-interface">
                <h2>
                    User Interface
                </h2>
                <section id="filters-component">
                    <h3>
                        Filters Component
                    </h3>
                    <p>
                        The filters component contains all the available filters that a user can utilize to filter and target specific post topics and categories.
                        There are two filtering tabs that offer different kind of functionalities
                    </p>
                    <ul>
                        <li>
                            The Monitoring Tab allows the user to utilize the filtered search mode that fetches posts collected by the Twitter crawler module
                        </li>
                        <li>
                            The On-the-fly Tab allows the user to utilize the on-the-fly search mode that fetches posts straight from the Twitter API and provides the analytics on-the-fly.
                        </li>
                    </ul>    
                    <section>
                        <h4>
                            Monitoring Tab
                        </h4>
                        <p>
                            In the Monitoring mode, the filters component contains 6 filtering options.  
                            This filtering options are combined with an AND operator and fetch the necessary results.
                            E.g.: The query that will be sent if we press Get Analytics button in  will be:
                            usecase: “Border Surveillance” AND language: “English” AND date: from April 2 2023 until April 10 2023 AND hide retweets: true
                        </p>
                        <img alt='smas filters' src={smasFilters} style={{ width: "100%", minWidth: '200px', margin: 'auto 0'}}/>
                        <section>
                            <h5>
                                Use cases
                            </h5>
                            <p>
                                With this filter the user is able to select between the topics that are monitored.
                                Allowed values are: Air quality, Border surveillance, Water quality, Water quality (AoI).
                            </p>
                            <img alt='smas usecases filter' src={smasUseCasesFilter} style={{ width: "100%", minWidth: '200px', maxWidth: '280px', margin: 'auto 0'}}/>
                        </section>
                        <section>
                            <h5>
                                Language
                            </h5>
                            <p>
                                With this filter (), the user can select the language in which the posts are written.
                                Each use case has different languages to select and when the Use case filter is changed the languages are updated accordingly.
                            </p>
                            <img alt='smas language filter' src={smasLanguageFilter} style={{ width: "100%", minWidth: '200px', maxWidth: '280px', margin: 'auto 0'}}/>
                        </section>
                        <section>
                            <h5>
                                Date period
                            </h5>
                            <p>
                               Date period filter is used to select the start and end dates that the posts were published.
                                The date query is in UTC time abbreviation.
                            </p>
                            <img alt='smas date filter' src={smasDateFilter} style={{ width: "100%", minWidth: '200px', maxWidth: '400px', margin: 'auto 0'}}/>
                        </section>
                        <section>
                            <h5>
                                Keyword
                            </h5>
                            <p>
                               Keyword filter is used to select keywords by which the posts were selected. Each use case and language pair have its own separate keyword list.  When the Use case or Language filter is changed by the user the keyword dropdown is updated with the according keywords of the use case and language pair. These keywords are subject to change over the course of the project.
                                The recommendation list drops down when the filter is clicked.
                                The user can add a keyword to the filter with 2 ways:
                            </p>
                            <ul>
                                <li>
                                    By selecting a keyword from the dropdown list of the filter.
                                </li>
                                <li>
                                    By clicking a keyword from the Keyword cloud widget results.
                                </li>
                            </ul>
                            <p>
                                Each separate keyword or key phrase selected is combined using the OR operator.
                                E.g., The query that will be sent with the keywords selected in  will be:
                                keyword: “Departure point” OR “Libya Conflict” OR “Turkey Border”
                                The posts and analytics fetched will contain only one of the 
                            </p>
                            <img alt='smas keyword filter' src={smasKeywordFilter} style={{ width: "100%", minWidth: '200px', maxWidth: '400px', margin: 'auto 0'}}/>
                        </section>
                        <section>
                            <h5>
                                Location
                            </h5>
                            <p>
                                Location filter is used to select the location detected in the posts text by our localization tool.
                                The recommendation list drops down when the filter is clicked and contains all the keywords found with the given filters.
                                The user can add a location to the filter with 2 ways:
                            </p>
                            <ul>
                                <li>
                                    By selecting a location from the dropdown box.
                                </li>
                                <li>
                                    By clicking a location on the Most mentioned locations widget.
                                </li>
                            </ul>
                            <p>
                                Each separate location selected is combined using the OR operator
                                E.g., The query that will be sent with the locations selected in  will be:
                                location: Libya OR Sudan OR Greece
                                The posts fetched will have only one of the selected locations inside its text.
                            </p>
                            <img alt='smas location filter' src={smasLocationFilter} style={{ width: "100%", minWidth: '200px', maxWidth: '400px', margin: 'auto 0'}}/>
                        </section>
                                                <section>
                            <h5>
                                Hide retweets
                            </h5>
                            <p>
                                Hide retweets filter is used to filter out the retweets from the database.
                            </p>
                            <img alt='smas location filter' src={smasHideRetweetsFilter} style={{ width: "100%", minWidth: '200px', maxWidth: '360px', margin: 'auto 0'}}/>
                        </section>
                    </section>
                    <section>
                        <h4>
                            On-the-fly Tab
                        </h4>
                        <p>
                            In the On-the-fly tab, the filtering component contains:
                        </p>
                        <ul>
                            <li>
                                A free-text input field where the user is able to type their new keyword(s) 
                            </li>
                            <li>
                                A hide retweet switch that filters out the retweets when active.
                            </li>
                        </ul>
                        <p>
                            By pressing the Get Analytics button the backend queries the Twitter API directly and provides the results and analytics on-the-fly.
                        </p>
                        <img alt="smas on-the-fly filters" src={smasOnTheFlyFilters} style={{width: "100%"}}/>
                        <div>
                            <p><b><i>The basic keyword operators that can be used in the input field:</i></b></p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            Operator
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Keyword
                                        </td>
                                        <td>
                                            Matches a keyword within the body of a Tweet.  This is a tokenized match, meaning that your keyword string will be matched against the tokenized text of the Tweet body. Tokenization splits words based on punctuation, symbols, and Unicode basic plane separator characters.
                                            For example, a Tweet with the text “I like coca-cola” would be split into the following tokens: I, like, coca, cola. These tokens would then be compared to the keyword string used in your query.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            #
                                        </td>
                                        <td>
                                            Matches any Tweet containing a recognized hashtag, if the hashtag is a recognized entity in a Tweet. This operator performs an exact match, NOT a tokenized match.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            @
                                        </td>
                                        <td>
                                            Matches any Tweet that mentions the given username, if the username is a recognized entity.
                                            Example: (@twitterdev OR @twitterapi) -@twitter
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </section>
                <section id="widgets-component">
                    <h3>
                        Widgets Component
                    </h3>
                    <p>
                        In both search modes, the results are displayed in six different widgets that constitute the Widgets component.  
                        The widgets are updated all simultaneously after the user presses the Get Analytics button from the Filters Component
                    </p>
                    <img alt="widgets overview" src={widgetsOverview} style={{width: "100%"}}/>
                    <section>
                        <h4>
                            Total posts widget
                        </h4>
                        <p>
                            Total Posts widget shows the number of all retrieved posts as well as the number of posts by each social media platform separately.
                            The user can click on the platform chips in order to filter the retrieved posts by platform.
                        </p>
                        <img alt="total posts widget" src={totalPostsWidget} style={{width: "75%", maxWidth: '400px'}}/>
                    </section>
                    <section>
                        <h4>
                            Timeline widget
                        </h4>
                        <p>
                            Timeline visualizes the original posts retrieved from the database.
                            <br/>
                            From top to bottom the information each post contains is:
                            <br/>
                            •	The platform origin in the top right corner
                            <br/>
                            •	The post’s text
                            <br/>
                            •	The date it was originally posted expressed in UTC time abbreviation
                            <br/>
                            •	The keywords detected in the text
                            <br/>
                            •	The locations detected in the text
                            <br/>
                            The user can click on the location chips and the Heatmap widget will zoom in to the location mentioned.
                        </p>
                        <img alt="Timeline widget" src={timelineWidget} style={{width: "75%", maxWidth: '400px'}}/>
                    </section>
                    <section>
                        <h4>
                            Most mentioned locations widget
                        </h4>
                        <p>
                            Most Mentioned Locations presents the top ten locations that are mentioned most frequently in the retrieved posts’ text.  
                            The user can click on the location so he can add it to the location filter.
                        </p>
                        <img alt="total posts widget" src={mostMentionedLocationsWidget} style={{width: "75%", maxWidth: '400px'}}/>
                    </section>
                    <section>
                        <h4>
                            Heatmap widget
                        </h4>
                        <p>
                            Heatmap widget visualizes the distribution of the most-mentioned locations on a map. 
                            <br/>
                            The user can click on the location chips in the Timeline widget and the Heatmap widget will zoom in to the location mentioned.
                            <br/>
                            The user can also click on a pin in the post markers view and the selected post will be highlighted in the timeline.
                        </p>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <img alt="Clustermap widget" src={clustersHeatmapWidget} style={{ width: "49%" }}/>
                            <img alt="Heatmap widget" src={heatmapWidget} style={{ width: "49%" }}/>
                        </div>                        
                    </section>
                    <section>
                        <h4>
                            Number of posts per day widget
                        </h4>
                        <p>
                            Number of Posts per Day shows a line chart with the number of posts retrieved everyday by each platform (and in total).
                        </p>
                        <img alt="Number of posts per day widget" src={postsPerDayWidget} style={{width: "75%", maxWidth: '600px'}}/>
                    </section>
                    <section>
                        <h4>
                            Keyword cloud widget
                        </h4>
                        <p>
                            Keyword Cloud visualizes the most mentioned keywords in the posts’ text and sizes them according to their frequency.  User can click on a keyword to add it to the keywords field.
                        </p>
                        <img alt="Word cloud widget" src={wordCloudWidget} style={{width: "75%", maxWidth: '450px'}}/>
                    </section>
                </section>
            </section>
            <section id="api-reference">
                <h2>
                    API reference
                </h2>
                <section id="query-parameters">
                    <h3>
                        Query parameters 
                    </h3>
                    <div>
                        <h4>
                            Filtered query parameters
                        </h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Endpoint
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Values
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        usecase <br/> <b>required</b>
                                    </td>
                                    <td>
                                        The use case the posts were retrieved for.
                                    </td>
                                    <td>
                                        air_quality, border_surveillance, water_quality, water_quality_aoi
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        lang <br/> <b>required</b>
                                    </td>
                                    <td>
                                        The language of the post’s text. Two letter ISO 639-1 codes are 
                                        supported.
                                    </td>
                                    <td>
                                        Air quality: en, it, fr, sp, de, gr <br/>
                                        Border Surveillance: en, tr, ar, bs, mk <br/>
                                        Water Quality: nl, it <br/>
                                        Water Quality AoI: en, nl, it
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        from <br/> <b>required</b>
                                    </td>
                                    <td>
                                        A timestamp in ms representing the starting date
                                    </td>
                                    <td>
                                        E.g. 1669284293671
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        to <br/> <b>required</b>
                                    </td>
                                    <td>
                                        A timestamp in ms representing the ending date
                                    </td>
                                    <td>
                                        E.g. 1669889093671
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        keyword
                                    </td>
                                    <td>
                                        A string representing the keyword the post was selected for. The 
                                        string can be a partial word so it can fetch all of its variants
                                    </td>
                                    <td>
                                        E.g., Migration, Conflict, Air quality, pollution, pollutant, pollut
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        location
                                    </td>
                                    <td>
                                        A string representing one of the locations detected in the text. 
                                    </td>
                                    <td>
                                        E.g. London, Turkey, Ohio
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        platform
                                    </td>
                                    <td>
                                        A string representing the social media platform the posts were retrieved from.
                                    </td>
                                    <td>
                                        E.g. Twitter, Instagram
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        retweeted
                                    </td>
                                    <td>
                                        A Boolean representing whether the posts list include retweeted posts.
                                    </td>
                                    <td>
                                        true, false
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h4>
                            On-the-fly query parameters
                        </h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Endpoint
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Values
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        keyword
                                    </td>
                                    <td>
                                        A string that represents the query used on the Twitter API. These search queries are 
                                        created with a set of operators that match on Tweet and user attributes, such as message 
                                        keywords, hashtags, and URLs. The rules to build a query are the same as the twitter API 
                                        manual
                                    </td>
                                    <td>
                                        E.g. 
                                        air (quality OR pollution) paris,
                                        (“human trafficking” OR arrest) (Libya OR Morocco)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        retweeted
                                    </td>
                                    <td>
                                        A Boolean that specifies whether the results should contain retweeted posts
                                    </td>
                                    <td>
                                        true, false
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </section>
                    <section id="endpoints">
                    <h3>
                        Endpoints
                    </h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Endpoint
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Response
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>/total</b>
                                    </td>
                                    <td>
                                        Returns a list of posts that match the query parameters
                                    </td>
                                    <td>
                                        <pre>
                                            <code>
                                                {
`{
    "total": 100,  
    "twitter": 50,
    "instagram": 50,
}`
                                                }
                                            </code>
                                        </pre>
                                    </td>                                         
                                </tr>
                                <tr>
                                    <td>
                                        <b>/timeline</b>
                                    </td>
                                    <td>
                                        Returns a list of posts with its data
                                    </td>
                                    <td>
                                        <pre>
                                            <code>
                                                {
`[
    {
        "_id": "645bb45ba3635b19aec17ef5",
        "id_str": "1656316222663806976",
        "full_text": "US Defeat in Syrian Conflict: 
                    Syrian President Receives Official
                    Invitation to Participate in 
                    Upcoming Arab League Summit
                    #Africa #US #Libya #weapon #EU #War
                    #Breaking #UN #Asia",
        "timestamp_ms_long": 1683731535000,
        "platform": "twitter",
        "keywords": {
            "keyword": "Libya",
            "must_exist_keyword": "Conflict"
        },
        "estimated_locations": [
            {
                "location_in_text": "Libya",
                "location_fullname": "Libya",
                "geometry": {
                    "type": "Point",
                    "coordinates": [
                        18.1236723,
                        26.8234472
                    ]
                }
            }, ...
        ]
    }, ...
]`
                                                }
                                            </code>
                                        </pre>
                                    </td>                                         
                                </tr>
                                <tr>
                                    <td>
                                        <b>/locations</b>
                                    </td>
                                    <td>
                                        Returns a list of locations with the number of 
                                        times the location is detected in the fetched posts
                                    </td>
                                    <td>
                                        <pre>
                                            <code>
                                                {
`{
    "Turkey": 5377,
    "Sudan": 4600,
    "UK": 4530,
    "Greece": 4429,
    "France": 3122,
    "Libya": 1685,
    "Lampedusa": 1279,
    "Morocco": 516,
    "Spain": 325,
    "Syria": 270,
    "Askari Plaza": 199,
    "Lahore": 199
}`
                                                }
                                            </code>
                                        </pre>
                                    </td>                                         
                                </tr>
                                <tr>
                                    <td>
                                        <b>/heatmap</b>
                                    </td>
                                    <td>
                                        Returns a list of post ids and its location 
                                        coordinates.
                                    </td>
                                    <td>
                                        <pre>
                                            <code>
                                                {
`[
    {
        "id": "6458e44b1c11eb0ae7c47b84",
        "coordinates": [
            48.684055799999996,
            9.009657397144327
        ]
    },
    {
        "id": "6458e4581c11eb0ae7c47b8d",
        "coordinates": [
            35.511134150000004,
            12.59629135962961
        ]
    }...
]`
                                                }
                                            </code>
                                        </pre>
                                    </td>                                         
                                </tr>
                                <tr>
                                    <td>
                                        <b>/postsperday</b>
                                    </td>
                                    <td>
                                        Returns a list of dates separated by a day and the 
                                        number of posts made in each day.
                                    </td>
                                    <td>
                                        <pre>
                                            <code>
                                                {
`{
    "twitter": [
        {
            "count": 1184,
            "date": "2023-05-08"
        },
        {
            "count": 465,
            "date": "2023-05-09"
        },
        {
            "count": 280,
            "date": "2023-05-10"
        }
    ],
    "instagram": [
        {
            "count": 26,
            "date": "2023-05-08"
        },
        {
            "count": 9,
            "date": "2023-05-09"
        },
        {
            "count": 9,
            "date": "2023-05-10"
        }
    ],
    "total": [
        {
            "count": 1210,
            "date": "2023-05-08"
        },
        {
            "count": 474,
            "date": "2023-05-09"
        },
        {
            "count": 289,
            "date": "2023-05-10"
        }
    ],
    "events": [
        {
            "count": 1210,
            "date": "2023-05-08"
        }
    ]
}`
                                                }
                                            </code>
                                        </pre>
                                    </td>                                         
                                </tr>
                                <tr>
                                    <td>
                                        <b>/keyword</b>
                                    </td>
                                    <td>
                                        Returns a list of the keywords and the number of 
                                        mentions in the posts. The list is sorted in 
                                        descending order by the most mentioned.
                                    </td>
                                    <td>
                                        <pre>
                                            <code>
                                                {
`{
    "Libya Migrant": 720,
    "Turkey Border": 312,
    "Brigade Arrest": 226,
    "Turkey Migrant": 99,
    "Brigade Border": 82,
    "Turkey Conflict": 62,
    "Morocco Border": 41,
    "Turkey Arrest": 41,
    "Turkey Migration": 38
}`
                                                }
                                            </code>
                                        </pre>
                                    </td>                                         
                                </tr>
                            </tbody>
                        </table>
                </section>
            </section>
        </main>
        </div>
    </div>
    );
}