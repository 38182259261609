export default function WordCloudPopup() {
    return (
        <div>
            Shows a word cloud of the most used keywords in the posts.
            <br/>
            These keywords are predefined and subject to change in the future.
            <br/>
            By clicking on one of the words the user can filter the posts by word.
        </div>
    );
}