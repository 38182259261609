import { useContext } from "react";
import { DashboardContext } from "contexts/DashboardContext";
import DownloadPostsPopup from "./DownloadPostsPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./DownloadPostsButton.css";

export default function DownloadPostsButton() {
    const context = useContext(DashboardContext);

    return (
        <button
            style={{
                padding: "4px 8px",
                backgroundColor: "gray",
            }}
        >
            <FontAwesomeIcon
                icon={faDownload}
                size="1x"
                onClick={() => {
                    context.setPopupTitle("Download posts");
                    context.setPopupContent(<DownloadPostsPopup />);
                    context.setPopupOpen(true);
                }}
            />
        </button>
    );
}