import './WordCloud.css';

import { TagCloud } from "react-tagcloud";
import { Component } from "react/cjs/react.production.min";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {DashboardContext} from "contexts/DashboardContext";

class WordCloud extends Component {
    static contextType = DashboardContext;

    constructor(props, context) {
        super(props, context);
        this.state = { loading: false, data: [] };
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextContext.shouldWordCloudUpdate || !nextState.loading;
    }

	componentDidUpdate() {
		if(this.context.shouldWordCloudUpdate) {
			this.fetchData();
			this.context.setShouldWordCloudUpdate(false);
		}
	}

    async fetchData() {
        const endpoint = this.context.endpoint + '/keywords';
        const headers = {
            'Accept': '*/*',
            'Content-Type': 'application/json',
        };

		let body;
		if(this.context.mode === 'filtered') {
			body = JSON.stringify({
                usecase: this.context.usecase,
                lang: this.context.language,
                from: this.context.fromDate,
                to: this.context.toDate,
                keyword: this.context.keyword,
                location: this.context.location,
                platform: this.context.platform,
                retweeted: this.context.retweeted,
            });
		}
		else if(this.context.mode === 'onthefly') {
			body = JSON.stringify({
                keyword: encodeURIComponent(this.context.keyword),
                retweeted: this.context.retweeted,
            });
		}

        fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: body
        })
        .then(this.setState({ loading: true }))
        .then(res => res.json())
        .then(res => {
            let tempDataList = [];
            for(let key in res) {
                let temp = { value: key, count: res[key] };
                tempDataList.push(temp);
            }
            this.setState({ loading: false, data: tempDataList });
        })
        .catch(err => {
            console.log('Wordcloud error: ', err);
        }); 
    }
    
    onClickWord = (value) => {
        if (this.context.keywordList.includes(value))
            this.context.setKeywordList(prevList => { 
                const list = prevList.filter(item => item !== value)
                const string = this.context.listToString(list);
                this.context.setKeyword(string);
                return list;
            });
        else
            this.context.setKeywordList(prevList => {
                const list = [...prevList, value];
                const string = this.context.listToString(list);
                this.context.setKeyword(string);
                return list
            });
    }  

    render() {
        if(this.state.loading) {
            return(
                <FontAwesomeIcon icon={faSpinner} size="5x" color="#144767" spin />
            )
        }
        return (
            <div className="WordCloud">
                <TagCloud
                    style={{ 
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center" 
                    }}
                    minSize={14}
                    maxSize={48}
                    tags={this.state.data}
                    disableRandomColor={true}
                    onClick={(tag) => this.onClickWord(tag.value)}
                    renderer={(tag, size, color) => {
                        return  <span key={tag.value} className='Word' style={{ color: color, fontSize: size, margin: "0 5px" }}>
                                    {tag.value}
                                </span>
                    }}
                />
            </div>
        );
    }
}

export default WordCloud;