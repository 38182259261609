import "./Post.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import LocationCard from "./LocationCard";

function renderURL(text) {
    let urlRegex = /(https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/g;
    if (urlRegex.test(text)) {
        text = text.replace(urlRegex, (url) => {
            return `<a href=${url} target='_blank'>${url}</a>`;
        });
    }
    return text;
}

function replaceInstagramURL(url) {
    const p = url.split("/");
    let t = "";
    for (let i = 0; i < p.length; i++) {
        if (i === 2) {
            t += p[i].replaceAll("-", "--")
                    .replaceAll(".", "-")
                + atob("LnRyYW5zbGF0ZS5nb29n")
                + "/";
        } else {
            if (i !== p.length - 1)
                t += p[i] + "/";
            else t += p[i];
        }
    }
    return encodeURI(t);
}



export default function GenericPost(props) {
    let icon, color;
    switch (props.platform) {
        case "twitter":
            icon = faTwitter;
            color = "#1DA1F2";
            break;
        case "instagram":
            icon = faInstagram;
            color = '#833AB4';
            break;
        default:
            icon = faTwitter;
    }

    let uniqueLocations = [];
    if(props.estimated_locations !== undefined && props.estimated_locations.length > 0) {
        uniqueLocations = props.estimated_locations.filter((value, index, self) =>
            index === self.findIndex((t) => (
               t.location_in_text === value.location_in_text
            ))
        )
    }

    return (
        <div className="Post" key={props.index} id={props.focused? 'Focused': ''}>

            <div className="IconSection">
                {props.focused &&
                    <div className = "FocusedIcon">
                        <FontAwesomeIcon
                            icon={faStar}
                            size="1x"
                            color="orange"
                        />
                    </div>
                    }
                <div className="BrandIcon">
                    <FontAwesomeIcon
                        icon={icon}
                        size="2x"
                        color={color}
                    />
                </div>
            </div>
            <div
                className="Text"
                dangerouslySetInnerHTML={{
                    __html: renderURL(props.text),
                }}
            ></div>
            {props.imageURL !== undefined ? (
                <img
                    src={
                        props.platform === "twitter"
                            ? props.imageURL
                            : replaceInstagramURL(props.imageURL)
                    }
                    alt="Not Found"
                />
            ) : null}
            <div className="Date">
                <FontAwesomeIcon
                    icon={faCalendar}
                    size="1x"
                    color="#1DA1F2"
                    className="ListIcon"
                />
                {new Date(props.date).toUTCString()}
            </div>
            {props.keywords.length > 0 && (
                <div style={{ display: "flex" }}>
                    <FontAwesomeIcon
                        icon={faKey}
                        size="1x"
                        className="ListIcon"
                    />
                    <div className="Chips">
                        {props.keywords.map((item, key) => {
                            if (item !== undefined && item !== null)
                                return (
                                    <span className="Chip Keyword" key={key}>
                                        {item}
                                    </span>
                                );
                            return null;
                        })}
                    </div>
                </div>
            )}
            {uniqueLocations.length > 0 && (
                <div style={{ display: "flex" }}>
                    <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        size="1x"
                        className="ListIcon"
                    />
                    <div className="Chips">
                        {uniqueLocations.map((item, key) => {
                            if (item !== undefined && item !== null && item.location_in_text !== undefined)
                                return (
                                    <LocationCard
                                        key={key}
                                        locationName={item.location_in_text}
                                        locationGeoData={item.geometry.coordinates}
                                    />
                                );
                            return null;
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}
