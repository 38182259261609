import "./Filters.css";
import "react-widgets/styles.css";
import { switchIconStyle } from "./Common/FilterStyles";

import { Component } from "react/cjs/react.production.min";
import logo from "./logo512.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-solid-svg-icons/faSun";
import { faMoon } from "@fortawesome/free-solid-svg-icons/faMoon";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Switch from "react-switch";
import MonitoringTab from "./Tabs/Monitoring/MonitoringTab";
import OnTheFlyTab from "./Tabs/OnTheFly/OnTheFlyTab";
import { DashboardContext } from "contexts/DashboardContext";

export default class Filters extends Component {
    static contextType = DashboardContext;

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            tabSelected: "filtered",
            languageLabel: "English",
            usecaseLabel: "Border Surveillance",
            darkModeToggle: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props){
            this.setState({ locations: this.props.locations });
        } 
    }

    handleUsecaseChange = (e) => {
        let defaultLanguage = "en";
        let defualtLanguageLabel = "English";
        if (e.value === "water_quality") {
            defaultLanguage = "nl";
            defualtLanguageLabel = "Dutch";
        }
        this.setState({
            usecaseLabel: e.label,
            languageLabel: defualtLanguageLabel,
        });
        this.context.setLanguage(defaultLanguage);
        this.context.setUsecase(e.value);
    };

    handleLanguageChange = (e) => {
        this.setState({ languageLabel: e.label });
        this.context.setLanguage(e.value);
    };

    handleFromDateChange = (from) => {
        this.context.setFromDate(from.getTime() - (from.getTimezoneOffset() * 60000) - 43200000);
    };

    handleToDateChange = (to) => {
        this.context.setToDate(to.getTime() - (to.getTimezoneOffset() * 60000) + 43199999);
    };

    handleKeywordChange = (keywords) => {
        this.context.setKeywordList(keywords);
        const string = this.context.listToString(keywords);
        this.context.setKeyword(string);
    }

    handleLocationChange = (locations) => {
        this.context.setLocationList(locations);
        const string = this.context.listToString(locations);
        this.context.setLocation(string);
    }

    handleRetweetedChange = () => {
        this.context.setRetweeted(!this.context.retweeted);
    };

    handleDarkModeChange = () => {
        let tempToggle = !this.state.darkModeToggle;
        this.setState({ darkModeToggle: tempToggle });
        this.props.toggleDarkMode(tempToggle);
    };

    onClickOnTheFlySearch = () => {
        this.context.setShouldDashboardUpdate(true);
    };

    onClickFilteredSearch = () => {
        this.context.setShouldDashboardUpdate(true);
    };

    onSelectTab(index) {
        this.setState({
            tabIndex: index,
        });
        this.context.setEndpoint(index === 0 ? this.context.url + "" : this.context.url + "/onthefly");
        this.context.setKeyword("");
        this.context.setRetweeted(false);
        this.context.setMode(index === 0 ? "filtered" : "onthefly");
    }

    render() {
        return (
            <div className="FilterBar">
                <div className="FiltersContainer">
                    <div className="LogoContainer">
                        <img src={logo} alt="logo" className="Logo" />
                    </div>
                    <Tabs
                        selectedIndex={this.tabIndex}
                        onSelect={(index) => {
                            this.onSelectTab(index);
                        }}
                    >
                        <TabList>
                            <Tab>Monitoring</Tab>
                            <Tab>On-the-fly search</Tab>
                            <div className="FlexFloatRight">
                                <Switch
                                    checked={this.state.darkModeToggle}
                                    onChange={this.handleDarkModeChange}
                                    offColor="#AAAAAA"
                                    onColor="#323264"
                                    handleDiameter={22}
                                    borderRadius={4}
                                    checkedIcon={
                                        <div style={switchIconStyle}>
                                            <FontAwesomeIcon
                                                style={{ color: "white" }}
                                                icon={faSun}
                                            />
                                        </div>
                                    }
                                    uncheckedIcon={
                                        <div style={switchIconStyle}>
                                            <FontAwesomeIcon icon={faMoon} />
                                        </div>
                                    }
                                />
                            </div>
                        </TabList>

                        <TabPanel>
                            <MonitoringTab
                                url={this.context.endpoint}
                                usecase={this.context.usecase}
                                usecaseLabel={this.state.usecaseLabel}
                                language={this.context.language}
                                languageLabel={this.state.languageLabel}
                                fromDate={this.context.fromDate}
                                toDate={this.context.toDate}
                                keywords={this.context.keyword}
                                locations={this.context.locationList}
                                retweeted={this.context.retweeted}
                                handleUsecaseChange={this.handleUsecaseChange}
                                handleLanguageChange={this.handleLanguageChange}
                                handleFromDateChange={this.handleFromDateChange}
                                handleToDateChange={this.handleToDateChange}
                                handleKeywordChange={this.handleKeywordChange}
                                handleLocationChange={this.handleLocationChange}
                                handleRetweetedChange={this.handleRetweetedChange}
                                onClickFilteredSearch={this.onClickFilteredSearch}
                            />
                        </TabPanel>
                        <TabPanel>
                            <OnTheFlyTab
                                retweeted={this.context.retweeted}
                                handleRetweetedChange={
                                    this.handleRetweetedChange
                                }
                                handleKeywordChange={(e) => {
                                    this.context.setKeyword(e.target.value);
                                }}
                                onClickSearch={this.onClickOnTheFlySearch}
                            />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        );
    }
}


