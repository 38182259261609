import "./Timeline.css";

import { Component } from "react/cjs/react.production.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import GenericPost from "./Posts/GenericPost";
import { DashboardContext } from "contexts/DashboardContext";

export default class Timeline extends Component {
    static contextType = DashboardContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            hasMoreToFetch: true,
            isFetchingData: false,
            postsDownloaded: [],
            postsInTimeline: [],
            postsListIndex: 0,
        };
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextContext.shouldTimelineUpdate || this.state.postsInTimeline !== nextState.postsInTimeline;
    }

    componentDidUpdate() {
        if(this.context.shouldTimelineUpdate) {
            this.context.setShouldTimelineUpdate(false);
            this.context.setFocusedPost(null);
            this.fetchData();
        }
    }

    fetchData = () => {
        this.state.postsListIndex = 0;
        this.setState({
            hasMoreToFetch: true,
            isFetchingData: true,
            postsDownloaded: [],
            postsInTimeline: [],
            postsListIndex: 0,
        });
        if (this.context.mode === "filtered") {
            this.fetchFilteredData();
        } else if (this.context.mode === "onthefly") {
            this.fetchOnTheFlyData();
        }
    };

    getNextData = () => {
        if (this.context.mode === "filtered") {
            this.fetchFilteredData();
        } else if (this.context.mode === "onthefly") {
            this.getNextOnTheFlyData();
        }
    };

    getNextOnTheFlyData = () => {
        this.setState({
            isFetchingData: false,
            postsInTimeline: this.state.postsInTimeline.concat(
                this.state.postsDownloaded.slice(
                    this.state.postsListIndex * 25,
                    (this.state.postsListIndex + 1) * 25
                )
            ),
            hasMoreToFetch:
                this.state.postsInTimeline.length + 25 <
                this.state.postsDownloaded.length,
            postsListIndex: this.state.postsListIndex + 1,
        });
    };

    fetchOnTheFlyData = () => {
        if (this.state.isFetchingData === true) return;

        const endpoint = this.context.endpoint + "/timeline";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                keyword: encodeURIComponent(this.context.keyword),
                retweeted: this.context.retweeted,
            }),
        }).then((res) => res.json())
        .then((res) => {
            this.setState({
                isFetchingData: false,
                postsListIndex: this.state.postsListIndex + 1,
                postsDownloaded: res,
                postsInTimeline: res.slice(0, 25),
                hasMoreToFetch: true
            });
        }).catch((err) => {
            console.log("Timeline error: ", err);
        });
    };

    fetchFilteredData = () => {
        const endpoint = this.context.endpoint + "/timeline";
        const headers = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };
        const body = JSON.stringify({
            usecase: this.context.usecase,
            lang: this.context.language,
            from: this.context.fromDate,
            to: this.context.toDate,
            keyword: this.context.keyword,
            location: this.context.location,
            page: this.state.postsListIndex,
            platform: this.context.platform,
            retweeted: this.context.retweeted,
        });

        fetch(endpoint, {
            method: "POST",
            headers: headers,
            body: body,
        }).then((res) => res.json())
        .then((res) => {
            this.setState({
                isFetchingData: false,
                hasMoreToFetch: res.length > 0,
                postsListIndex: this.state.postsListIndex + 1,
                postsInTimeline: this.state.postsInTimeline.concat(res),
            });
        }).catch((err) => {
            console.log("Timeline error: ", err);
        });
    };

    renderCard(item, key, focused = false) {
        return <GenericPost
            key={key}
            index={item.id_str}
            text={item.full_text}
            imageURL={item.image_url}
            date={ this.context.mode === "filtered"? item.timestamp_ms_long : item.created_at }
            platform={item.platform}
            keywords={
                item.keywords !== undefined
                ? [
                    item.keywords.keyword,
                    item.keywords.must_exist_keyword,
                ]
                : []
            }
            estimated_locations = {item.estimated_locations}
            focused = {focused}
        />
    }

    render() {
        if(this.state.isFetchingData) {
            return(
                <FontAwesomeIcon icon={faSpinner} size="5x" color="#144767" spin />
            )
        }

        return (
            <div id="scrollableDiv" className="PostList">
                <InfiniteScroll
                    dataLength={this.state.postsInTimeline.length}
                    hasMore={this.state.hasMoreToFetch}
                    next={this.getNextData}
                    style={{
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    loader={
                        <div
                            style={{
                                padding: "20px 0",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faSpinner}
                                size="4x"
                                color="#144767"
                                spin
                            />
                        </div>
                    }
                    endMessage={<p>No more posts</p>}
                    scrollableTarget="scrollableDiv"
                >
                    {this.context.focusedPost &&
                        this.renderCard(this.context.focusedPost, 0, true)
                    }
                    {this.state.postsInTimeline.map((post, key) =>
                        this.renderCard(post, key)
                    )}
                </InfiniteScroll>
            </div>
        );
    }
}
