export const languageOptions = {
    air_quality: [
        { value: "en", label: "English" },
        { value: "it", label: "Italian" },
        { value: "fr", label: "French" },
        { value: "es", label: "Spanish" },
        { value: "de", label: "German" },
        { value: "el", label: "Greek" },
    ],
    border_surveillance: [
        { value: "en", label: "English" },
        { value: "tr", label: "Turkish" },
        { value: "ar", label: "Arabic" },
        { value: "bs", label: "Bosnian" },
        { value: "mk", label: "Macedonian" },
    ],
    water_quality_aoi: [
        { value: "en", label: "English" },
        { value: "nl", label: "Dutch" },
        { value: "it", label: "Italian" },
    ],
    water_quality: [
        { value: "en", label: "English" },
        { value: "nl", label: "Dutch" },
        { value: "it", label: "Italian" },
    ],
};

export const usecaseOptions = [
    { value: "air_quality", label: "Air Quality" },
    { value: "border_surveillance", label: "Border Surveillance" },
    { value: "water_quality_aoi", label: "Water Quality (AoI)" },
    { value: "water_quality", label: "Water Quality" },
];
