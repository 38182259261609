import { createContext, useState } from "react";

export const DashboardContext = createContext();

export default function DashboardProvider({ children }) {
    const [url, setUrl] = useState(
        './api/public/index.php/api',
        //"http://127.0.0.1:8000/api",
    );
    const [endpoint, setEndpoint] = useState(url + "");
    const [mode, setMode] = useState("filtered");
    const [usecase, setUsecase] = useState("border_surveillance");
    const [language, setLanguage] = useState("en");
    const [fromDate, setFromDate] = useState(new Date().getTime() - 604800000);
    const [toDate, setToDate] = useState(new Date().getTime());
    const [keyword, setKeyword] = useState("");
    const [keywordList, setKeywordList] = useState([]);
    const [location, setLocation] = useState("");
    const [locationList, setLocationList] = useState([]);
    const [platform, setPlatform] = useState("");
    const [retweeted, setRetweeted] = useState(true);
    const [mapRef,setMapRef] = useState();
    const [focusedPost, setFocusedPost] = useState();
    const [mostMentionedLocations, setMostMentionedLocations] = useState([]);
    const [shouldTotalPostsUpdate, setShouldTotalPostsUpdate] = useState(false);
    const [shouldTimelineUpdate, setShouldTimelineUpdate] = useState(false);
    const [shouldLocationsUpdate, setShouldLocationsUpdate] = useState(false);
    const [shouldWordCloudUpdate, setShouldWordCloudUpdate] = useState(false);
    const [shouldLineChartUpdate, setShouldLineChartUpdate] = useState(false);
    const [shouldHeatmapUpdate, setShouldHeatmapUpdate] = useState(false);
    const setShouldDashboardUpdate = (flag) => {
        setShouldTotalPostsUpdate(flag);
        setShouldTimelineUpdate(flag);
        setShouldLocationsUpdate(flag);
        setShouldWordCloudUpdate(flag);
        setShouldLineChartUpdate(flag);
        setShouldHeatmapUpdate(flag);
    }

    const [popupOpen, setPopupOpen] = useState(false);
    const [popupTitle, setPopupTitle] = useState("");
    const [popupContent, setPopupContent] = useState("");

    const [totalPosts, setTotalPosts] = useState(0);

    const listToString = (list) => {
        let string = "";
        list.forEach((item) => {
            if (string === "") string = item;
            else string += "," + item;
        });
        return string;  
    };

    return(

        <DashboardContext.Provider
            value={{
                // Filters
                mode, setMode,
                url, setUrl,
                endpoint, setEndpoint,
                usecase, setUsecase,
                language, setLanguage,
                fromDate, setFromDate,
                toDate, setToDate,
                keyword, setKeyword, keywordList, setKeywordList,
                location, setLocation, locationList, setLocationList,
                platform, setPlatform,
                retweeted, setRetweeted,
                
                //Widget data
                mostMentionedLocations, setMostMentionedLocations,
                mapRef, setMapRef,
                focusedPost, setFocusedPost,
                totalPosts, setTotalPosts,

                // Widget update flags
                setShouldDashboardUpdate,
                shouldTotalPostsUpdate, setShouldTotalPostsUpdate,
                shouldTimelineUpdate, setShouldTimelineUpdate,
                shouldLocationsUpdate, setShouldLocationsUpdate,
                shouldWordCloudUpdate, setShouldWordCloudUpdate,
                shouldLineChartUpdate, setShouldLineChartUpdate,
                shouldHeatmapUpdate, setShouldHeatmapUpdate,

                // Popup
                popupOpen, setPopupOpen,
                popupTitle, setPopupTitle,
                popupContent, setPopupContent,

                // Utilities
                listToString,
            }}
        >
            {children}
        </DashboardContext.Provider>
    )
}
