import "../../Filters.css";
import "react-widgets/styles.css";

import { Component } from "react/cjs/react.production.min";
import { DashboardContext } from "contexts/DashboardContext";
import Select from "react-select";
import Switch from "react-switch";
import Multiselect from "react-widgets/Multiselect";
import Datepicker from "components/Filters/Common/Datepicker/Datepicker";
import { usecaseOptions, languageOptions } from "components/Filters/Common/FilterOptions";
import { selectStyle } from "components/Filters/Common/FilterStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";

export default class MonitoringTab extends Component {
    static contextType = DashboardContext;

    constructor(props) {
        super(props);
        this.state = {
            fetchedKeywords: [],
            fetchedLocations: [],
            isFetchingKeywords: false,
            isFetchingLocations: false,
        };
    }

    fetchKeywords = () => {
        const endpoint = this.props.url + "/getKeywordsByUsecase";
        this.setState({ isFetchingKeywords: true });

        fetch(endpoint, {
            method: "POST",
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                usecase: this.props.usecase,
                lang: this.props.language,
            }),
        }).then((res) => res.json())
        .then((res) => {
            this.setState({
                fetchedKeywords: res,
                isFetchingKeywords: false,
            });
        })
        .catch((err) => {
            console.log("Keyword recommendation error: ", err);
        });
    };

    render() {
        return (
            <div style={{ marginBottom: 10 }} className="Flex-Row">
                <div>
                    <div style={{ marginBottom: 5 }} className="Filters">
                        <Select
                            defaultValue={usecaseOptions[1]}
                            value={{
                                value: this.props.usecase,
                                label: this.props.usecaseLabel,
                            }}
                            minWidth="210px"
                            options={usecaseOptions}
                            styles={selectStyle}
                            onChange={this.props.handleUsecaseChange}
                        />

                        <Select
                            value={{
                                value: this.props.language,
                                label: this.props.languageLabel,
                            }}
                            options={languageOptions[this.props.usecase]}
                            styles={selectStyle}
                            onChange={this.props.handleLanguageChange}
                            minWidth="150px"
                        />

                        <Datepicker
                            className="Filter"
                            fromDate={new Date(this.props.fromDate)}
                            toDate={new Date(this.props.toDate)}
                            fromChange={this.props.handleFromDateChange}
                            toChange={this.props.handleToDateChange}
                        />
                    </div>
                    <div className="Filters">
                        <Multiselect
                            busy={this.state.isFetchingKeywords}
                            data={this.state.fetchedKeywords}
                            value={this.context.keywordList}
                            onMouseDown={this.fetchKeywords}
                            onChange={(value) =>
                                this.props.handleKeywordChange(value)
                            }
                            filter="contains"
                            style={{ flex: 0.5, maxWidth: 325 }}
                            className="Filter"
                            placeholder="Enter keywords here"
                        />
                        <Multiselect
                            data={this.context.mostMentionedLocations}
                            value={this.context.locationList}
                            onChange = {(value) => 
                                this.props.handleLocationChange(value)
                            }
                            style={{ flex: 0.5, maxWidth: 325 }}
                            className="Filter"
                            placeholder="Choose a location"
                        />
                        <div className="Filter">
                            <label style={{ margin: "0 10px" }}>
                                <span>Hide retweets</span>
                            </label>
                            <Switch
                                className="Switch-Container"
                                checked={!this.props.retweeted}
                                onChange={this.props.handleRetweetedChange}
                                onColor="#323264"
                                handleDiameter={22}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                borderRadius={4}
                            />
                        </div>
                    </div>
                </div>
                <button
                    className="Filter"
                    onClick={this.props.onClickFilteredSearch}
                >
                    <span>Get Analytics</span>
                    <FontAwesomeIcon
                        style={{ marginLeft: 10 }}
                        icon={faSearch}
                    />
                </button>
            </div>
        );
    }
}
