export default function TimelinePopup() {
    return (
        <div>
            Show a timeline of the retrieved posts.
            <br/>
            Each post has from top to bottom
            <ul>
                <li>the platform (top-right)</li>
                <li>the text</li>
                <li>the date</li>
                <li>the locations detected inside the text</li>
            </ul>
            By clicking on one of the locations the heatmap zooms in to the referenced coordinates.
        </div>
    );
}