import "./App.css";

import React from "react/cjs/react.production.min";
import Filters from "./components/Filters/Filters";
import Dashboard from "./components/Dashboard/Dashboard";
import DashboardProvider from "./contexts/DashboardContext";
import Popup from "./components/Popups/Popup";
import Tooltip from "components/Tooltip/Tooltip";

function App() {

    function toggleDarkMode(toggle) {
        if(toggle)
            document.body.classList.toggle("Dark");
        else
            document.body.classList.remove("Dark");
    }

    return (
        <div className="App">
            <DashboardProvider>
                <Filters
                    toggleDarkMode={toggleDarkMode}
                />
                <Dashboard />
                <Tooltip />
                <Popup />
            </DashboardProvider>
        </div>
    );
}

export default App;
