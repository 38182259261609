import "../../Filters.css";
import "react-widgets/styles.css";

import { Component } from "react/cjs/react.production.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import Switch from "react-switch";

export default class OnTheFlyTab extends Component {
    render() {
        return (
            <div
                className="Flex-Row"
                style={{ marginBottom: 10, maxWidth: "1024px" }}
            >
                <div className="Filters">
                    <input
                        className="Filter"
                        style={{ width: "30vw", padding: "0 12px" }}
                        placeholder="Enter keyword or phrase here..."
                        type="text"
                        id="KeywordFilter"
                        onChange={this.props.handleKeywordChange}
                    />
                    <div className="Filter">
                        <label style={{ margin: "0 10px" }}>
                            <span>Hide retweets</span>
                        </label>
                        <Switch
                            className="Switch-Container"
                            checked={this.props.retweeted}
                            onChange={this.props.handleRetweetedChange}
                            onColor="#323264"
                            handleDiameter={22}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            borderRadius={4}
                            id="material-switch"
                        />
                    </div>
                </div>
                <button className="Filter" onClick={this.props.onClickSearch}>
                    Get Analytics
                    <FontAwesomeIcon
                        style={{ marginLeft: 10 }}
                        icon={faSearch}
                    />
                </button>
            </div>
        );
    }
}
