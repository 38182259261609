import App from "../App";
import PrivacyPolicyPage from "./PrivacyPolicy/PrivacyPolicyPage";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import UserManual from "./UserManual/UserManual";

export default function MyRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
                <Route path="/user-manual" element={<UserManual/>} />
            </Routes>
        </Router>
    );
}
