export default function HeatmapPopup() {
    return (
        <div>
            The heatmap contains 2 views: <br/>
            <ul>
                <li>The heat layer showing post density</li>
                <li>The cluster layer showing posts as markers</li>
            </ul>
            <br/>
            By clicking on one of the markers the user can view the selected post in the timeline view as highlighted.
        </div>
    );
}