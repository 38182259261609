import "./Locations.css";
import { Component } from "react/cjs/react.production.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import CountUp from "shared/CountUp/CountUp";
import { DashboardContext } from "contexts/DashboardContext";

export default class Locations extends Component {
    static contextType = DashboardContext;

    constructor(props, context) {
        super(props, context);
        this.state = { 
            loading: false, 
            dataNames: [], 
            dataCounts: [], 
            data: [],
        };
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextContext.shouldLocationsUpdate || !nextState.loading;
    }

    async componentDidUpdate() {
        if(this.context.shouldLocationsUpdate) {
            this.fetchData();
            this.context.setShouldLocationsUpdate(false);
        }
    }

    async fetchData() {
        const endpoint = this.context.endpoint + "/locations";
        const headers = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let body;
        if (this.context.mode === "filtered") {
            body = JSON.stringify({
                usecase: this.context.usecase,
                lang: this.context.language,
                from: this.context.fromDate,
                to: this.context.toDate,
                keyword: this.context.keyword,
                location: this.context.location,
                platform: this.context.platform,
                retweeted: this.context.retweeted,
            });
        } else if (this.context.mode === "onthefly") {
            body = JSON.stringify({
                keyword: encodeURIComponent(this.context.keyword),
                retweeted: this.context.retweeted,
            });
        }

        fetch(endpoint, {
            method: "POST",
            headers: headers,
            body: body,
        }).then(this.setState({ loading: true }))
        .then((res) => {
            return res.json()
        }).then((res) => {
            //parse res and set dataNames and dataCounts 
            let names = Object.keys(res);
            let counts = Object.values(res);

            let data = [];
            names.forEach((name, index) => {
                //check if name is number
                if (isNaN(name))
                    data = [...data, {name: name, count: counts[index]}];
            });

            this.setState({
                loading: false,
                data: data,
            });
            this.context.setMostMentionedLocations(Object.keys(res));
        }).catch((err) => {
            console.log("Locations error: ", err);
        });
    }

    onClickLocationItem = (value) => {
        if (this.context.locationList.includes(value))
            this.context.setLocationList(prevList => { 
                const list = prevList.filter(item => item !== value)
                const locationString = this.context.listToString(list);
                this.context.setLocation(locationString);
                return list;
            });
        else
            this.context.setLocationList(prevList => {
                const list = [...prevList, value];
                const locationString = this.context.listToString(list);
                this.context.setLocation(locationString);
                return list
            });
    }

    render() {
        let elementList = [];
        for (let i = 0; i < this.state.data.length && i < 10; i++) {
            let element = (
                <li key={i}>
                    <LocationField
                        onClick={this.onClickLocationItem}
                        locationName={this.state.data[i].name}
                        locationCount={this.state.data[i].count}
                    />
                </li>
            );
            elementList.push(element);
        }

        return (
            this.state.loading ?
            <FontAwesomeIcon
                icon={faSpinner}
                size="5x"
                color="#144767"
                spin
            />
            :
            <div className="listContainer">
                <ol className="LocationList">{elementList}</ol>
            </div>
        );
    }
}

class LocationField extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isSelected: false,
            locationName: this.props.locationName,
            locationCount: this.props.locationCount
        };
    }

    handleOnClick = (element) => {
        // if (this.state.isSelected) {
        //     element.target.classList.remove("selected");
        // } else {
        //     element.target.className += " selected";
        // }
        this.props.onClick(this.state.locationName);
        this.setState({ isSelected: !this.state.isSelected });
    }
    
    render() {
        const counterDuration = 1.5;
        return (
            <>
                <div 
                onClick={this.handleOnClick} 
                className="textContainer wide">
                    {this.state.locationName}
                </div>
                <div className="textContainer slim">
                    <span className="locationCount">
                        <CountUp
                            className="PlatformCounter"
                            start={0}
                            end={this.state.locationCount}
                            duration={counterDuration}
                        />
                    </span>
                </div>
            </>
        );
    }
}
