import { useState, useEffect } from 'react';

export default function CountUp({start, end, duration, className}) {
    const [counter, setCounter] = useState(start);

    useEffect(() => {
        if(counter < end) {
            setTimeout(() => {
                setCounter(counter + 1);
            }, duration * 1000 / (end - start) );
        }
    });

    return(
        <span className={className}>
            {counter.toLocaleString('de')}
        </span>
    );
}