import './TotalTweets.css';
import { Component } from "react/cjs/react.production.min";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'shared/CountUp/CountUp';
import PlatformCounter from './PlatformCounter';
import {DashboardContext} from "contexts/DashboardContext";

class TotalTweets extends Component {
    static contextType = DashboardContext;

    constructor(props, context) {
        super(props, context);
        this.state = { 
            loading: false,
            totalCounter: 0,
            instagramCounter: 0,
            twitterCounter: 0 
        };
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextContext.shouldTotalPostsUpdate || !nextState.loading;
    }

    async componentDidUpdate() {
        if(this.context.shouldTotalPostsUpdate) {
            this.fetchData();
            this.context.setShouldTotalPostsUpdate(false);
        }
    }

    async fetchData() {
        const endpoint = this.context.endpoint + '/total';
        const headers = {
            'Accept': '*/*',
            'Content-Type': 'application/json',
        };
             
		let body;
		if(this.context.mode === 'filtered') {
			body = JSON.stringify({
                usecase: this.context.usecase,
                lang: this.context.language,
                from: this.context.fromDate,
                to: this.context.toDate,
                keyword: this.context.keyword,
                location: this.context.location,
                platform: this.context.platform,
                retweeted: this.context.retweeted,
            });
		}
		else if(this.context.mode === 'onthefly') {
			body = JSON.stringify({
                keyword: encodeURIComponent(this.context.keyword),
                retweeted: this.context.retweeted,
            });
		}

        fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: body
        })
        .then(this.setState({ loading: true }))
        .then(res => res.json())
        .then(res => {
            this.setState({ 
                loading: false, 
                totalCounter: res.total,
                instagramCounter: res.instagram,
                twitterCounter: res.twitter
            });
            this.context.setTotalPosts(res.total);
        })
        .catch(err => {
            console.log('Total tweets error: ', err);
        });
    }

    render() {
        if(this.state.loading) {
            return(
                <FontAwesomeIcon icon={faSpinner} size="5x" color="#144767" spin />
            )
        }

        let platformCounters = [];
        if(this.state.twitterCounter > 0)
            platformCounters.push(
                <PlatformCounter 
                    key='twitter'
                    count={this.state.twitterCounter}
                    platform='twitter'
                />
            );

        if(this.state.instagramCounter > 0)
            platformCounters.push(
                <PlatformCounter 
                    key='instagram'
                    count={this.state.instagramCounter}
                    platform='instagram'
                />
            );

        return(
            <div className='TotalTweets'>
                <CountUp
                    className='TotalCounter'
                    start={0}
                    end={this.state.totalCounter}
                    duration={1.5}
                />
                <div className='PlatformCounters'>
                    {platformCounters}
                </div>
            </div>
        )
    }
}

export default TotalTweets