import { useContext } from "react"
import { DashboardContext } from "contexts/DashboardContext"
import "./Popup.css"

export default function Popup() {
    const context = useContext(DashboardContext);

    return(
        <div
            style={{
                visibility: context.popupOpen ? "visible" : "hidden",
                opacity: context.popupOpen ? "1" : "0",
            }}
            className="Overlay"
        >
            <div className="Popup">
                <div className="Header">
                    <h2>{ context.popupTitle }</h2>
                    <span className="Close" onClick={() => { context.setPopupOpen(false) }}>
                        &times;
                    </span>
                </div>
                <div className="Content">
                    { context.popupContent }
                </div>
            </div>
        </div>
    )
}