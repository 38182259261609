import "./Tooltip.css";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { DashboardContext } from "contexts/DashboardContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock, faBookOpen } from "@fortawesome/free-solid-svg-icons";
import PrivacyPolicyPopup from "components/Popups/PrivacyPolicyPopup/PrivacyPolicyPopup";

export default function Tooltip() {
    const context = useContext(DashboardContext);
    const navigate = useNavigate();
    return (
        <div className="Tooltip">
            <button
                className="HoveringButton"
                onClick={() => {
                    context.setPopupTitle("Privacy policy");
                    context.setPopupContent(
                        <PrivacyPolicyPopup />
                    );
                    context.setPopupOpen(true);
                }}
            >
                <FontAwesomeIcon
                    icon={faUserLock}
                    size="1x"
                    color="white"
                />
                <p>Privacy policy</p>
            </button>
            <button
                className="HoveringButton"
                onClick={() => {
                    //redirect to user namuanl page
                    navigate("/user-manual")
                }}
            >
                <FontAwesomeIcon
                    icon={faBookOpen}
                    size="1x"
                    color="white"
                />
                <p>User manual</p>
            </button>
        </div>
    );
}