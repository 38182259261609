import { Component } from "react/cjs/react.production.min";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Filler,
	Title,
	Tooltip,
	Legend,
	LineController,
	BubbleController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import './LineChart.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { DashboardContext } from "contexts/DashboardContext";

class LineChart extends Component {
	static contextType = DashboardContext;

    constructor(props, context) {
        super(props, context);
		ChartJS.register(
			LineController,
			BubbleController,
			CategoryScale,
			LinearScale,
			PointElement,
			LineElement,
			Filler,
			Title,
			Tooltip,
			Legend,
		);
		this.state = { 
			loading: false, labels: [], 
			total: [], twitter: [], instagram: [], events: [],
			isTwitterZero: true, isInstaZero: true, isEventsZero: true,
		};
		this.fetchData();
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextContext.shouldLineChartUpdate || !nextState.loading;
    }

	async componentDidUpdate() {
		if(this.context.shouldLineChartUpdate) {
			this.fetchData();
			this.context.setShouldLineChartUpdate(false);
		}
	}

	async fetchData() {
		const endpoint = this.context.endpoint + '/postsperday';
		const headers = {
			'Accept': '*/*',
			'Content-Type': 'application/json',
		};

		let body;
		if(this.context.mode === 'filtered') {
			body = JSON.stringify({
                usecase: this.context.usecase,
                lang: this.context.language,
                from: this.context.fromDate,
                to: this.context.toDate,
                keyword: this.context.keyword,
                location: this.context.location,
				platform: this.context.platform,
                retweeted: this.context.retweeted,
            });
		}
		else if(this.context.mode === 'onthefly') {
			body = JSON.stringify({
                keyword: encodeURIComponent(this.context.keyword),
                retweeted: this.context.retweeted,
            });
		}

		fetch(endpoint, {
			method: 'POST',
			headers: headers,
			body: body,
		})
		.then(this.setState({ loading: true }))
		.then(res => res.json())
		.then(res => {
			let tempLabels = [];
			let tempTotal = [];
			
			for(let i = 0; i < res.total.length; i++) {
				tempLabels.push(res.total[i]['date']);
				tempTotal.push(res.total[i]['count']);
			}

			let tempTwitter = [];
			let isTwitterZero = true;
			let tempInsta = [];
			let isInstaZero = true;
			let tempEvents = [];
			let isEventsZero = true;

			for(let i = 0; i < tempLabels.length; i++) {
				if(res.twitter !== undefined) {
					let dateFound = false;
					for(let j = 0; j < res.twitter.length; j++){
						if(res.twitter[j]['date'] !== tempLabels[i]) continue;

						tempTwitter.push(res.twitter[j]['count']);
						isTwitterZero = false;
						dateFound = true;
						break;			
					}
					if(!dateFound) tempTwitter.push(0);
				}

				if(res.instagram !== undefined) {
					let dateFound = false;
					for(let j = 0; j < res.instagram.length; j++) {
						if(res.instagram[j]['date'] !== tempLabels[i]) continue;

						tempInsta.push(res.instagram[j]['count']);
						isInstaZero = false;
						dateFound = true;
						break;
					}
					if(!dateFound) tempInsta.push(0);
				}

				if(res.events !== undefined) {
					let dateFound = false;
					for(let j = 0; j < res.events.length; j++) {
						if(res.events[j]['date'] !== tempLabels[i]) continue;
						this.sendNotificationForEvent(res.events[j]['date']);
						tempEvents.push(res.events[j]['count']);
						isEventsZero = false;
						dateFound = true;
						break;
					}
					if(!dateFound) tempEvents.push(null);
				}
			}

			this.setState({
				loading: false, labels: tempLabels, total: tempTotal,
				twitter: tempTwitter, isTwitterZero: isTwitterZero,
				instagram: tempInsta, isInstaZero: isInstaZero,
				events: tempEvents, isEventsZero: isEventsZero
			});
		})
		.catch(err => {
			console.log('Linechart error: ', err);
		});   
	}

	async sendNotificationForEvent(date) {
		const endpoint = this.context.endpoint + '/notify';
		const request = "?usecase=" + encodeURIComponent(this.context.usecase) + "&date=" + encodeURIComponent(date) + "&language=" + encodeURIComponent(this.context.language);
		const headers = {
			'Accept': '*/*',
			'Content-Type': 'application/json',
		};
		fetch(endpoint + request, {
			method: 'GET',
			headers: headers,
			mode: 'no-cors',
		})
		.then(res => res.json())
		.then(res => {
			console.log(res);
		})
		.catch(err => {
			console.log('Linechart error: ', err);
		});
	}

	isZeroDataset(data) {
		data.forEach(d => {
			if(d > 0) return false;
		});
		return true;
	}

	render() {
		if(this.state.loading) {
			return(
				<FontAwesomeIcon icon={faSpinner} size="5x" color="#144767" spin />
			)
		}

		let dataset = [{
				label: 'Total',
				type: 'line',
				fill: false,
				data: this.state.total,
				borderColor: '#DC9B4B',
			}]
		if(!this.state.isTwitterZero)
			dataset.push({
				label: 'Twitter',
				type: 'line',
				fill: false,
				data: this.state.twitter,
				borderColor: '#1DA1F2',
			});
		if(!this.state.isInstaZero)
			dataset.push({
				label: 'Instagram',
				type: 'line',
				fill: false,
				data: this.state.instagram,
				borderColor: '#833AB4',
			});
		if(!this.state.isEventsZero)
			dataset.push({
				label: 'Event',
				type: 'bubble',
				fill: false,
				data: this.state.events,
				borderColor: '#FF0000',
				radius: 8,
			});

		return(
			<div className="LineChart">
				<Chart
					options={{
						responsive: true,
						plugins: { 
							legend: { position: 'top' },
						},
					}}
					data={{
						labels: this.state.labels,
						datasets: dataset
					}}
				/>
			</div>
		);
	}
}

export default LineChart;