import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import CountUp from 'shared/CountUp/CountUp';
import { DashboardContext } from "contexts/DashboardContext";
import './TotalTweets.css';

export default function PlatformCounter(props) {
    const context = useContext(DashboardContext);
    const counterDuration = 1.5;
    
    let icon, color;
    if(props.platform === 'twitter') {
        color='#1DA1F2';
        icon = faTwitter;
    }
    else if(props.platform === 'instagram') {
        color ='#833AB4';
        icon = faInstagram;
    }

    return(
        <span 
            className='PlatformCounterContainer' 
            onClick={() => {
                context.platform !== props.platform
                    ? context.setPlatform(props.platform)
                    : context.setPlatform("");
                context.setShouldDashboardUpdate(true)
            }}
            style={{backgroundColor: context.platform === props.platform ? '#e2ad6c' : ''}}
        >
            <FontAwesomeIcon icon={icon} color={color}/>
            <CountUp 
                className='PlatformCounter'
                start={0}
                end={props.count}
                duration={counterDuration}
            />
        </span>
    )
}