import { useState, useContext, useEffect } from "react";
import { DashboardContext } from "contexts/DashboardContext";
import { Parser } from "json2csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck, faCross } from '@fortawesome/free-solid-svg-icons';
const { transforms: { unwind } } = require('json2csv');

export default function DownloadPostsPopup() {
    const context = useContext(DashboardContext);

    const supportedFormats = ["csv", "json"];
    const [selectedFormat, setSelectedFormat] = useState(supportedFormats[0]);
    const [progressMessage, setProgressMessage] = useState("");
    const [data, setData] = useState(null);

    useEffect(() => {
        if(data === null) return;

        const downloadData = (data) => {
            fileData = `data:text/${selectedFormat};chatset=utf-8,${encodeURIComponent(data)}`;
            const link = document.createElement("a");
            link.href = fileData;
            link.download = `${context.usecase}_${context.language}.${selectedFormat}`;
            link.click();
        }

        let fileData = '';
        if(selectedFormat === 'csv') {
            const fields = ['id_str', 'full_text', 'timestamp_ms_long', 'platform', "image_url", 'keywords.keyword', 'keywords.must_exist_keyword', 'estimated_locations.location_in_text' ];
            const transforms = [unwind({ paths: ['estimated_locations'], blankOut: false })];
            const json2csv = new Parser({ fields, transforms });
            downloadData(json2csv.parse(data));
        } else if(selectedFormat === 'json') {
            downloadData(JSON.stringify(data, null, 2));
        }

    }, [data]);

    const fetchTimelineData = () => {
        const endpoint = context.endpoint + "/timeline";
        const headers = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let body;
        if (context.mode === "filtered") {
            body = JSON.stringify({
                usecase: context.usecase,
                lang: context.language,
                from: context.fromDate,
                to: context.toDate,
                keyword: context.keyword,
                location: context.location,
                platform: context.platform,
                retweeted: context.retweeted,
            });
        } else if (context.mode === "onthefly") {
            body = JSON.stringify({
                keyword: encodeURIComponent(this.context.keyword),
                retweeted: this.context.retweeted,
            });
        }

        setProgressMessage(
        <span style={{ color: "darkblue" }}>
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faSpinner} spin />
            <b>Downloading {context.totalPosts} posts</b>
        </span>);

        fetch(endpoint, {
            method: "POST",
            headers: headers,
            body: body,
        }).then((res) => res.json())
        .then((res) => {
            setData(res);
        }).catch((err) => {
            setProgressMessage(
            <span style={{ color: "red" }}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCross} />
                <b>Error downloading timeline data</b>
            </span>);
        }).finally(() => {
            setProgressMessage(
            <span style={{ color: "green" }}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCheck} />
                <b>Downloaded {context.totalPosts} posts sucessfully</b>
            </span>);
        });
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {supportedFormats.map((format) => (
                    <button 
                        onClick={() => {setSelectedFormat(format)}}
                        style={{
                            flex: 1,
                            marginRight: "8px",
                            height: "80px",
                            borderRadius: "5px",
                            boxShadow: "none",
                            border: selectedFormat === format ? "3px solid" : "1px solid",
                            borderColor: selectedFormat === format ? "#DC9B4B" : "#888",
                            color: selectedFormat === format ? "#DC9B4B" : "#000",
                            backgroundColor: "#FFFFFF88",
                            fontSize: "18px",
                        }}
                    >
                        {format}
                    </button>
                ))}
            </div>
            <div style={{ display: "flex", marginTop: "15px" }}>
                <button style={{ flex: 1, height: "50px" }} onClick={ fetchTimelineData }>
                    Download
                </button>
            </div>
            <div style={{ display: "flex", marginTop: "15px" }}>
                {progressMessage}
            </div>
        </>
    );
}