export default function PrivacyPolicyPopup() {
    return(
        <>
        <p>
            Last updated January 31 2023 <br />
            This privacy policy for{" "}
            <a href="https://callisto-h2020.eu/">
                CALLISTO
            </a>{" "}
            (Copernicus Artificial Intelligence Services and
            data fusion with other distributed data sources and
            processing at the edge to support DIAS and HPC
            infrastructures, GA 101004152) Social Media
            Analytics Suite (SMAS), describes our policies and
            procedures on the collection, use and disclosure any
            information collected when you use the Suite,
            informing you about your privacy rights and the way
            you can be legally protected.
        </p>
        <section>
            <h3>Data Responsible</h3>
            <p>
                The responsibility for the data of the CALLISTO
                SMAS is on: <br />
                INFORMATION TECHNOLOGIES INSTITUTE / CENTRE FOR
                RESEARCH AND TECHNOLOGY HELLAS (ITI/CERTH){" "}
                <br />
                6th Km Charilaou - Thermi Road
            </p>
        </section>
        <section>
            <h3>
                What kind of data are being collected by the
                CALLISTO SMAS?
            </h3>
            <p>
                Public social media posts from Twitter and
                Instagram, related to citizens observations or
                news coverage around use cases relevant to (1)
                water quality assessment, (2) air quality
                assessment for satellite journalism and (3) land
                border change detection are being collected and
                presented in this SMAS. CALLISTO project has
                issued a dedicated{" "}
                <a href="https://m4d.iti.gr/wp-content/uploads/2022/12/CALLISTO-Data-Protection-Notice.pdf">
                    Data Protection Notice
                </a>{" "}
                as an effort of enabling the data subjects to be
                informed about the data processing and to
                exercise their rights.
            </p>
            <p>
                All data have been collected in accordance with
                the licences and terms & conditions of the data
                providers and have been gathered only from
                public accounts, with the permission defined by
                the social media platforms (Twitter/Instagram)
                and in compliance with the respective terms of
                use, including the ones referred explicitly to
                the terms of use on behalf of minors, thus in
                accordance with user expectation of privacy. In
                accordance with the data minimisation principle,
                only the parts of the social media posts that
                are deemed necessary for the project’s
                objectives will be processed subject to a
                privacy-by-design technique, while the majority
                will be deleted immediately. The server hosting
                this database is accessible only by authorised
                users through authentication (using passwords of
                high complexity). A firewall will also be in
                place to allow only specific (whitelisted) IPs
                to access the server and to restrict the access
                of each whitelisted IP only to specific
                ports/services. Different access privileges to
                the data are available to ensure that the
                authorised users will only have access to the
                stored data on a need-to-know basis, i.e., that
                the authorised users will have access only to
                the stored pseudonymized data needed to fulfil
                their tasks
            </p>
            <p>
                All data processing, the purpose of which is (i)
                for scientific research purposes, (ii) to
                facilitate the functionality of other modules of
                the project, especially in terms of their
                usefulness in the relevant use cases, and (iii)
                for demonstration purposes, adhere to the{" "}
                <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                    EU General Data Protection Regulation
                    (2016/679).
                </a>
            </p>
            <p>
                The storage duration of the data in their
                pseudonymised form will be the duration of the
                project plus five (5) years after the end of the
                project [i.e., December 2023], to be available
                for demonstration in case of an inspection or an
                audit, or until it is not needed for these
                purposes anymore.
            </p>
        </section>
        <section>
            <h3>
                What kind of data is being collected when you
                access the CALLISTO SMAS page?
            </h3>
            <p>
                We{" "}
                <b>
                    <u>
                        do not collect any personal information
                        and more in particular any special
                        categories of personal data (art. 9(1)
                        GDPR), or data relating to criminal
                        convictions (art. 10 GDPR), or to minors
                    </u>
                </b>
                , at least not intentionally apart from any
                incidental usage data that may be automatically
                collected, including, but not limited to, the
                type of webpage you use, such as: the IP
                address, operating system, the type of Internet
                browser you use, unique device identifiers and
                other diagnostic data.All incidental usage data
                that may be considered relevant for personal
                identification will be immediately isolated and
                erased from all storage devices and will not be
                processed at any stage and under any format
            </p>
            <p>
                We may update Our Privacy Policy from time to
                time. The updated version will be indicated by
                an updated ‘revised’ date, and it will be
                effective when uploaded. Please make sure to
                review this Privacy Policy periodically for any
                changes.
            </p>
        </section>
        <section>
            <h3>Contact Us</h3>
            <p>
                If you have any questions or comments about this
                Privacy Policy, or you want to exercise your
                rights, you may contact us by email.
            </p>
        </section>
        </>
    )
}