import "./Heatmap.css";
import { Component } from "react/cjs/react.production.min";
import L, { Icon } from "leaflet";
import "leaflet.heat";
import "leaflet.markercluster";
import { DashboardContext } from "contexts/DashboardContext";
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png"

class Heatmap extends Component {

    static contextType = DashboardContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            map: "",
            heatLayer: "",
            markerLayer: "",
            controlLayer: "",
            data: [],
        };
    }

    async componentDidMount() {
        this.createMap();
        this.fetchData();
    }

    componentDidUpdate() {
        if (this.context.shouldHeatmapUpdate) {
            this.fetchData();
            this.context.setShouldHeatmapUpdate(false);
        }
    }

    async getPostById(id) {
        const endpoint = this.context.endpoint + "/post/get?id=" + id;
        const headers = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        fetch(endpoint, {
            method: "GET",
            headers: headers
        }).then((res) => {
            if(!res.ok) throw new Error(res.status);
            return res.json()
        }).then((res) => {
            this.context.setFocusedPost(res);
            let element = document.getElementById('Focused');
            if(element) {
                element.scrollIntoView({ behavior:'smooth' })
            }
        }).catch((err) => {
            console.log("Error: ", err);
        });
    }

    async fetchData() {
        const endpoint = this.context.endpoint + "/heatmap";
        const headers = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let body;
        if (this.context.mode === "filtered") {
            body = JSON.stringify({
                usecase: this.context.usecase,
                lang: this.context.language,
                from: this.context.fromDate,
                to: this.context.toDate,
                keyword: this.context.keyword,
                location: this.context.location,
                platform: this.context.platform,
                retweeted: this.context.retweeted,
            });
        } else if (this.context.mode === "onthefly") {
            body = JSON.stringify({
                keyword: encodeURIComponent(this.context.keyword),
                retweeted: this.context.retweeted,
            });
        }

        this.removeLayer(this.state.markerLayer);
        this.removeLayer(this.state.heatLayer);
        this.removeLayer(this.state.controlLayer);

        fetch(endpoint, {
            method: "POST",
            headers: headers,
            body: body,
        }).then(this.setState({loading: true}))
        .then((res) => res.json())
        .then((res) => {
            let coordinates = res.map((item) => {
                return item.coordinates
            })
            this.setState({data: coordinates});
            let heat = this.addHeatLayer(coordinates);
            let markers = this.addMarkerLayer(res);
            this.addControlLayer(this.state.map, heat, markers);
        })
        .catch((err) => {
            console.log("Heatmap error: ", err);
        });
    }

    addTileLayer(map) {
        L.tileLayer(
            "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
            {
                attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            }
        ).addTo(map);
    }

    addMarkerLayer = (data) => {
        let markerLayer = L.markerClusterGroup({});
        data.map((item) => {
            let marker = L.marker(new L.LatLng(item.coordinates[0], item.coordinates[1]), {
                icon: new Icon({
                    iconUrl: markerIconPng,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41]
                })
            }).on("click", () => {
                this.getPostById(item.id);
            }).addTo(markerLayer);

            return marker;
        });
        markerLayer.addTo(this.state.map);

        this.setState({ markerLayer: markerLayer })
        
        return markerLayer;
    }

    addHeatLayer = (data) => {
        let heatLayer = L.heatLayer(data, {
            max: 100,
            minOpacity: 100,
            radius: 2,
            blur: 3,
            gradient: {0.5: "blue", 0.75: "lime", 1: "red"},
        });

        this.setState({ heatLayer: heatLayer })

        return heatLayer;
    }

    addControlLayer = (map, heatLayer, markerLayer) => {
        let controlLayer = L.control.layers({
            "Heatmap": heatLayer,
            "Post Markers": markerLayer
        }).addTo(map)

        this.setState({ controlLayer: controlLayer })
    }

    removeLayer = (layer) => {
        if (layer !== "") {
            layer.remove();
        }
    }

    createMap = () => {
        if (this.state.map !== "")  return;

        let map = L.map("map", {
            zoomSnap: 0.05,
            zoom: 0,
            zoomControl: false,
            dragging: true,
            doubleClickZoom: true,
            scrollWheelZoom: true
        }).setView([45, 10], 3.5);

        this.addTileLayer(map);

        this.setState({ map: map });
        this.context.setMapRef(map);
    }

    render() {
        return (
            <div className="Map">
                <div id="map">
                </div>
            </div>
        );
    }
}


export default Heatmap;
