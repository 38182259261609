import { DashboardContext } from "contexts/DashboardContext";
import { useContext } from "react";

const LocationCard = (props) => {

    const {mapRef} = useContext(DashboardContext);


    function onClickLocationItem(){

        mapRef.flyTo([props.locationGeoData[1],props.locationGeoData[0]],7,{
            animate: true,
            duration: 3
        });
    }

    return (
            <span
                className="Chip Location"
                onClick={onClickLocationItem}
            >
                {props.locationName}
            </span>
    );
};

export default LocationCard;